import './index.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import React from 'react';
import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// eslint-disable-next-line import/no-extraneous-dependencies
import { OidcClient } from '@axa-fr/oidc-client';
import { OidcSecure } from '@axa-fr/react-oidc';
import Layout from './components/Layout';
import {
  LandingRoute,
  CatalogRoute,
  MySubscriptionsOverviewRoute,
  CatalogProductRoute,
  MyProductSubscriptionRoute,
  UseCaseRoute,
  AdminDataProductsOverview,
  AdminCreateDataProduct,
  AdminUpdateDataProduct,
  MyOfferingsOverviewRoute,
  MyProductOfferingRoute,
  OfferWizardIsWebserviceRoute,
  OfferWizardTellUsRoute,
  OfferWizardGetStartedRoute,
  OfferWizardLanguageRoute,
  OfferWizardAutoFillOdpsRoute,
  OfferWizardReviewRoute,
  OfferWizardCongratulationsRoute,
  OfferWizardYourProductRoute,
  OfferWizardProductVisibilityRoute,
  OfferWizardYourDatasetRoute,
  OfferWizardPricingPlansRoute,
  OfferWizardLicenseAndTermsRoute,
  OfferWizardDataHolderRoute,
  OfferWizardImagesRoute,
  SelectOfferWizardRoute,
  GenericOfferWizardYourProductRoute,
  GenericOfferWizardProductVisibilityRoute,
  GenericOfferWizardThemesRoute,
  GenericOfferWizardPricePlansroute,
  PricePlanForProductWizardRoute,
  PricePlanWizardRoute,
  PricePlansOverviewRoute,
  GenericOfferWizardReviewRoute,
} from './components/Routes';
import CatalogProductsOverview from './components/data-market/CatalogProductsOverview';
import MySubscriptionsOverview from './components/data-market/MySubscriptionsOverview';
import MySubscription from './components/data-market/MySubscription';
import CatalogProduct from './components/data-market/CatalogProduct';
import UseCase from './components/data-market/UseCase';
import CreateDataProduct from './components/admin/CreateDataProduct';
import DataProductsOverview from './components/admin/DataProductsOverview';
import UpdateDataProduct from './components/admin/UpdateDataProducts';
import MyOfferingsOverview from './components/data-market/MyOfferingsOverview';
import IsWebservice from './components/data-market/offer-wizard/1-IsWebservice';
import TellUs from './components/data-market/offer-wizard/2-TellUs';
import GetStarted from './components/data-market/offer-wizard/3-GetStarted';
import Language from './components/data-market/offer-wizard/4-Language';
import AutoFillOdps from './components/data-market/offer-wizard/5-AutoFillOdps';
import Review from './components/data-market/offer-wizard/13-Review';
import Congratulations from './components/data-market/offer-wizard/14-Congratulations';
import YourProduct from './components/data-market/offer-wizard/6-YourProduct';
import ProductVisibility from './components/data-market/offer-wizard/7-ProductVisibility';
import YourDataSet from './components/data-market/offer-wizard/8-YourDataset';
import PricingPlans from './components/data-market/offer-wizard/9-PricingPlans';
import LicenceAndTerms from './components/data-market/offer-wizard/10-LicenceAndTerms';
import DataHolder from './components/data-market/offer-wizard/11-DataHolder';
import Images from './components/data-market/offer-wizard/12-Images';
import ProductFormSelection from './components/data-market/offer-wizard/ProductFormSelection';
import GenericYourProduct from './components/data-market/generic-product-offer-wizard/1-GenericYourProduct';
import GenericProductVisibility from './components/data-market/generic-product-offer-wizard/3-GenericYourProductVisibility';
import GenericProductThemes from './components/data-market/generic-product-offer-wizard/2-GenericYourProductThemes';
import GenericYourProductPricePlans from './components/data-market/generic-product-offer-wizard/4-GenericYourProductPricePlan';
import PricePlan from './components/data-market/price-plans/1-PricePlan';
import PricePlansOverview from './components/data-market/MyPricePlansOverview';
import GenericYourProductReview from './components/data-market/generic-product-offer-wizard/5-GenericYourProductReview';

function App() {
  const getOidc = OidcClient.get;

  function createAuthHeaders(headers: any) {
    if (getOidc().tokens?.accessToken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${getOidc().tokens?.accessToken}`,
        },
      };
    }

    return {
      headers:
      {
        ...headers,
      },
    };
  }

  const authLink = setContext((request, { headers }) => (createAuthHeaders(headers)));
  const servicePortalApiGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_SERVICE_PORTAL_API_URL,
  });

  const cubeGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_CUBE_API_URL,
  });

  const cmsGraphqlLink = new HttpLink({
    uri: process.env.REACT_APP_CMS_API_URL,
    // in case of localhost, add headers here
    // local:
    // headers: {
    //   Authorization: 'users API-Key e3be7a3c-99ae-4626-a9e3-14a416132699',
    //   'wecity-roles': 'ORG_DATA_MARKET_SELLER,ORG_DATA_MARKET_BUYER',
    //   'wecity-organization-id': 'wecity',
    //   'wecity-user-id': '04216fac-780f-435a-bf56-726a6332de8f',
    // },
  });

  const client = new ApolloClient({
    link: authLink.concat(
      ApolloLink.split(
        (operation) => operation.getContext().clientName === 'cube',
        cubeGraphqlLink,
        ApolloLink.split(
          (operation) => operation.getContext().clientName === 'cms',
          cmsGraphqlLink,
          servicePortalApiGraphqlLink,
        ),
      ),
    ),
    cache: new InMemoryCache(
      {
        typePolicies: {
          DataMarket: {
            merge: true,
          },
        },
      },
    ),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path={LandingRoute()} element={<Layout />}>
            <Route path={CatalogRoute()} element={<CatalogProductsOverview />} />
            <Route path={CatalogProductRoute()} element={<OidcSecure><CatalogProduct /></OidcSecure>} />
            <Route path={UseCaseRoute()} element={<UseCase />} />

            <Route path={MySubscriptionsOverviewRoute()} element={<OidcSecure><MySubscriptionsOverview /></OidcSecure>} />
            <Route path={MyProductSubscriptionRoute()} element={<OidcSecure><MySubscription /></OidcSecure>} />

            <Route path={MyOfferingsOverviewRoute()} element={<OidcSecure><MyOfferingsOverview /></OidcSecure>} />
            <Route path={MyProductOfferingRoute()} element={<OidcSecure><MyOfferingsOverview /></OidcSecure>} />

            <Route path={OfferWizardIsWebserviceRoute()} element={<OidcSecure><IsWebservice /></OidcSecure>} />
            <Route path={OfferWizardTellUsRoute()} element={<OidcSecure><TellUs /></OidcSecure>} />
            <Route path={OfferWizardGetStartedRoute()} element={<OidcSecure><GetStarted /></OidcSecure>} />
            <Route path={OfferWizardLanguageRoute()} element={<OidcSecure><Language /></OidcSecure>} />

            <Route path={PricePlansOverviewRoute()} element={<OidcSecure><PricePlansOverview /></OidcSecure>} />
            <Route path={PricePlanForProductWizardRoute()} element={<OidcSecure><PricePlan /></OidcSecure>} />
            <Route path={PricePlanWizardRoute()} element={<OidcSecure><PricePlan /></OidcSecure>} />

            <Route path={GenericOfferWizardYourProductRoute()} element={<OidcSecure><GenericYourProduct /></OidcSecure>} />
            <Route path={GenericOfferWizardThemesRoute()} element={<OidcSecure><GenericProductThemes /></OidcSecure>} />
            <Route path={GenericOfferWizardProductVisibilityRoute()} element={<OidcSecure><GenericProductVisibility /></OidcSecure>} />
            <Route path={GenericOfferWizardPricePlansroute()} element={<OidcSecure><GenericYourProductPricePlans /></OidcSecure>} />
            <Route path={GenericOfferWizardReviewRoute()} element={<OidcSecure><GenericYourProductReview /></OidcSecure>} />

            <Route path={SelectOfferWizardRoute()} element={<OidcSecure><ProductFormSelection /></OidcSecure>} />

            <Route path={OfferWizardAutoFillOdpsRoute()} element={<OidcSecure><AutoFillOdps /></OidcSecure>} />
            <Route path={OfferWizardYourProductRoute()} element={<OidcSecure><YourProduct /></OidcSecure>} />
            <Route path={OfferWizardProductVisibilityRoute()} element={<OidcSecure><ProductVisibility /></OidcSecure>} />
            <Route path={OfferWizardYourDatasetRoute()} element={<OidcSecure><YourDataSet /></OidcSecure>} />
            <Route path={OfferWizardPricingPlansRoute()} element={<OidcSecure><PricingPlans /></OidcSecure>} />
            <Route path={OfferWizardLicenseAndTermsRoute()} element={<OidcSecure><LicenceAndTerms /></OidcSecure>} />
            <Route path={OfferWizardDataHolderRoute()} element={<OidcSecure><DataHolder /></OidcSecure>} />
            <Route path={OfferWizardImagesRoute()} element={<OidcSecure><Images /></OidcSecure>} />

            <Route path={OfferWizardReviewRoute()} element={<OidcSecure><Review /></OidcSecure>} />
            <Route path={OfferWizardCongratulationsRoute()} element={<OidcSecure><Congratulations /></OidcSecure>} />

            <Route path={AdminCreateDataProduct()} element={<OidcSecure><CreateDataProduct /></OidcSecure>} />
            <Route path={AdminDataProductsOverview()} element={<OidcSecure><DataProductsOverview /></OidcSecure>} />
            <Route path={AdminUpdateDataProduct()} element={<OidcSecure><UpdateDataProduct /></OidcSecure>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
