import { useEffect, useState } from 'react';
import { DataProductLocationType, DataProductThemeType, useGetDataProductFiltersQuery } from '../../generated/gql/types';
import { getIconForTheme } from '../../Helpers';

function getImagePathForTheme(theme: string): string | undefined {
  switch (theme?.toLowerCase()) {
    case 'fiets': return '/images/theme_bikes.jpg';
    case 'auto': return '/images/themes_car.jpg';
    case 'ov': return '/images/themes_public-transport.jpg';
    case 'doorstroming': return '/images/themes_traffic-flow.jpg';
    default: return undefined;
  }
}

function getImagePathForLocation(theme: string): string | undefined {
  switch (theme?.toLowerCase()) {
    case 'amsterdam': return '/images/location_amsterdam.jpg';
    case 'utrecht': return '/images/location_utrecht.jpg';
    case 'apeldoorn': return '/images/location_apeldoorn.jpg';
    case 'amersfoort': return '/images/location_amersfoort.jpg';
    case 'zwolle': return '/images/location_zwolle.jpg';
    default: return undefined;
  }
}

function useProductFilters(): ProductFilters | undefined {
  const { data, loading } = useGetDataProductFiltersQuery();

  const [filters, setFilters] = useState<ProductFilters | undefined>(undefined);

  useEffect(() => {
    if (data && data.dataProductFilter) {
      const allLocations = data.dataProductFilter.locations.map((t) => ({
        label: t.name,
        type: t.type,
        imagePath: getImagePathForLocation(t.name),
      }));

      setFilters({
        themes: data.dataProductFilter.themes.map((t) => ({
          label: t.name,
          type: t.type,
          imagePath: getImagePathForTheme(t.name),
          icon: getIconForTheme(t.name),
        })),
        allLocations,
        cities: allLocations.filter((l) => l.type === DataProductLocationType.City),
        provinces: allLocations.filter((l) => l.type === DataProductLocationType.Province),
        otherLocations: allLocations.filter((l) => l.type === DataProductLocationType.Other),

      });
    }
  }, [loading, data]);

  return filters;
}

export type ProductLocation = {
  label: string,
  type: DataProductLocationType,
  imagePath?: string,
};

export type ProductTheme = {
  label: string,
  type: DataProductThemeType,
  icon?: string,
  imagePath?: string
};

export type ProductFilters = {
  themes: ProductTheme[]
  allLocations: ProductLocation[]
  cities: ProductLocation[]
  provinces: ProductLocation[]
  otherLocations: ProductLocation[]
};
export default useProductFilters;
